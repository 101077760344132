<template>
	<div>
		<h2>{{ $t('invoice.suggestion_transaction') }}</h2>
		<CustomTable
            id_table="bridge_transactions_suggestions"
            ref="bridge_transactions"
            :items="bridge_transactions"
            :busy.sync="table_busy"
            primaryKey="bridgetransaction_id"
            :hide_if_empty="true"
            :externSlotColumns="extern_slot_columns"
        >
			<template v-slot:[`custom-slot-cell(account_name)`]="{ data }">
				{{ data.bridge_account.bridge_bank.bridgebank_name }} - {{ data.bridge_account.bridgeaccount_name }}<br>
				{{ data.bridge_account.bridgeaccount_iban }}
			</template>
    	</CustomTable>
	</div>
</template>

<script type="text/javascript">
    import OpenBank from "@/mixins/OpenBank.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: 'BankFlowSuggestion',
		props: ['accountingplan_id', 'amount', 'date', 'supplier', 'invoices_ids'],
		mixins: [OpenBank, Navigation],
		data () {
			return {
				extern_slot_columns: ['account_name'],
				bridge_transactions: [],
				table_busy: false,
				events_tab: {
                    'TableAction::goToAssignTransaction': this.goToAssignTransaction,
                },
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				const date_start = new Date(this.date)
				date_start.setMonth(date_start.getMonth() - 1)

				this.bridge_transactions = await this.getBridgeTransactionSuggestion(this.accountingplan_id, this.amount, date_start.toDateInputValue())
				this.table_busy = false
			},

			async goToAssignTransaction(bridge_transaction) {
				await this.assignBridgeTransaction(bridge_transaction.bridgetransaction_id, this.supplier, this.invoices_ids, undefined, '')
				this.$emit('submit')
			}
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>